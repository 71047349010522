<template>
  <div class="summary">
    <div class="page-title clearfix">
      <div class="float-left">
        <span>{{ $t("nav.summary") }}</span>
      </div>
    </div>
    <div class="main-content">
      <div
        class="total-info"
        :class="userRole !== '0' && userRole !== '1' ? 'low-level' : ''"
      >
        <router-link class="item" to="/data-manage/list">
          <p class="item-title">{{ this.$t("summary.total_datas") }}</p>
          <p class="item-number">{{ total.total_data }}</p>
        </router-link>
        <router-link class="item" to="/project/list">
          <p class="item-title">{{ this.$t("summary.total_projects") }}</p>
          <p class="item-number">{{ total.total_project }}</p>
        </router-link>
        <router-link v-if="userRole === '0'" class="item" to="/department/list">
          <p class="item-title">{{ this.$t("summary.total_departments") }}</p>
          <p class="item-number">{{ total.total_department }}</p>
        </router-link>
        <router-link
          v-if="userRole === '0' || userRole === '1'"
          class="item"
          to="/doctor/list"
        >
          <p class="item-title">{{ this.$t("summary.total_doctors") }}</p>
          <p class="item-number">{{ total.total_doctor }}</p>
        </router-link>
        <router-link v-if="userRole !== '3'" class="item" to="/student/list">
          <p class="item-title">{{ this.$t("summary.total_students") }}</p>
          <p class="item-number">{{ total.total_student }}</p>
        </router-link>
      </div>
    </div>
    <div class="chart-content">
      <div class="chart-item age-sex-chart">
        <div class="chart-title">{{ this.$t("summary.age_gender") }}</div>
        <v-chart class="chart" :option="ageDistribution" />
      </div>
      <div class="chart-item modality-chart">
        <div class="chart-title">{{ this.$t("summary.modality") }}</div>
        <v-chart class="chart" :option="modality" />
      </div>
      <div class="chart-item analysis-chart">
        <div class="chart-title">{{ this.$t("summary.application") }}</div>
        <v-chart class="chart" :option="analysis" />
      </div>
      <div class="chart-item tags-chart" v-if="showDataTag">
        <div class="chart-title">{{ this.$t("summary.tag") }}</div>
        <v-chart class="chart" :option="treemap" />
      </div>
      <div class="chart-item tags-chart" v-if="!showDataTag">
        <div class="chart-title">{{ this.$t("summary.tag") }}</div>
        <div class="chart-body">
          <div class="empty">{{ this.$t("_data.tags") }}：0</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, TreemapChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PolarComponent,
  GridComponent,
} from "echarts/components";
// import "echarts/lib/chart/bar";
// import "echarts/lib/component/tooltip";
// import "echarts/lib/component/polar";
// import "echarts/lib/component/legend";
// import "echarts/lib/component/title.js";
import { summaryRequest } from "../../api/api";
import { MessageBox, Loading } from "element-ui";
import { mapState, mapGetters } from "vuex";
use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TreemapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PolarComponent,
  GridComponent,
]);
export default {
  components: { "v-chart": ECharts },
  data() {
    return {
      total: {},
      ageDistribution: {},
      modality: {},
      treemap: {},
      analysis: {},
      summaryData: {},
      showDataTag: false,
      userRole: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).role
        : "",
      allData: null,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.language,
    }),
  },

  watch: {
    lang(newVal, oldVal) {
      if (newVal != oldVal) this.initData();
    },
  },
  mounted() {
    this.getSummaryData();
  },
  methods: {
    async getSummaryData() {
      let self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      // let resData = {
      //   "total_data": 228,
      //   "total_project": 0,
      //   "total_department": 5,
      //   "total_doctor": 5,
      //   "total_student": 4,
      //   "data_age": {},
      //   "data_series": {},
      //   "data_modality": {},
      //   "data_tags": [],
      //   "data_application": {
      //     "name": ["3D", "DTI", "FLAIR"],
      //   }
      // }
      try {
        let res = await summaryRequest();
        if (res.status === 200) {
          self.allData = res.data;
          self.initData();
        } else {
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    initData: function () {
      let summaryData = this.allData;
      this.total = {
        total_data: summaryData.total_data || "0",
        total_department: summaryData.total_department || "0",
        total_doctor: summaryData.total_doctor || "0",
        total_project: summaryData.total_project || "0",
        total_student: summaryData.total_student || "0",
      };

      let femalArr = summaryData.data_age.data_female || [0];
      let maleArr = summaryData.data_age.data_male || [0];
      let femalMax = Math.max(...femalArr);
      let maleMin = Math.min(...maleArr);
      let maleMax = Math.abs(maleMin);
      let xAxisMax = "";

      let noData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (femalMax === 0 && maleMax === 0) {
        xAxisMax = 500;
      } else if (femalMax > maleMax) {
        xAxisMax = Math.floor(femalMax * 1.2);
      } else {
        xAxisMax = Math.floor(maleMax * 1.2);
      }

      xAxisMax = xAxisMax < 10 ? 10 : xAxisMax;

      this.ageDistribution = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "rgba(0, 0, 0, 0)",
            },
          },
          formatter: function (obj) {
            return (
              obj[0].axisValue +
              "</br >" +
              obj[0].seriesName +
              "：" +
              obj[0].data +
              "</br >" +
              obj[1].seriesName +
              "：" +
              Math.abs(obj[1].data)
            );
          },
        },
        legend: {
          data: [this.$t("summary.male"), this.$t("summary.female")],
          bottom: 0,
        },
        grid: {
          left: 20,
          right: 30,
          bottom: 30,
          height: 340,
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            max: xAxisMax,
            min: -xAxisMax,
            axisLabel: {
              formatter: function (val) {
                return Math.abs(val);
              },
              color: "#7d7d7d",
              fontWeight: "11",
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisTick: {
              show: false,
            },
            data: [
              "0-4",
              "5-9",
              "10-14",
              "15-19",
              "20-29",
              "30-39",
              "40-49",
              "50-54",
              "55-59",
              "60-64",
              "60-69",
              "70-74",
              "75-79",
              "80-84",
              "85-89",
              "90+",
            ],
          },
        ],
        series: [
          {
            name: this.$t("summary.female"),
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "right",
              color: "#333",
              formatter: function (label) {
                const absValue = Math.abs(label.value);
                return absValue > 0 ? absValue : " ";
              },
            },
            itemStyle: {
              color: "#F5933F",
            },
            data: summaryData.data_age.data_female || noData,
          },
          {
            name: this.$t("summary.male"),
            type: "bar",
            barWidth: "10px",
            stack: "总量",
            label: {
              show: true,
              position: "left",
              color: "#333",
              formatter: function (label) {
                const absValue = Math.abs(label.value);
                return absValue > 0 ? absValue : " ";
                // return Math.abs(label.value)
              },
            },
            itemStyle: {
              color: "#7CB5EC",
            },
            data: summaryData.data_age.data_male || noData,
          },
        ],
      };

      let seriesArr = (summaryData.data_series.data &&
        summaryData.data_series.data.map(function (item) {
          return item.value;
        })) || [0];
      let seriesMax =
        Math.max(...seriesArr) === 0 ? 500 : Math.max(...seriesArr);
      let yAxisEmpty = new Array(12).fill(" ");
      let noModality = [
        {
          name: "模态",
          value: 0,
          itemStyle: {
            color: "#ebebeb",
          },
        },
      ];
      let emphasis = !!summaryData.data_modality.data
        ? {}
        : {
            itemStyle: {
              color: "#ebebeb",
            },
          };

      this.modality = {
        color: [
          "#ED925A",
          "#83B96C",
          "#7CB5EC",
          "#78C693",
          "#F8A780",
          "#C79C96",
          "#E8C358",
          "#ED928D",
          "#B8C458",
          "#7A8B9D",
          "#A7B481",
          "#EDAF5A",
          "#96BABE",
          "#9BB4D5",
          "#E3CB69",
          "#C080C4",
          "#78C693",
          "#CB9778",
          "#7B80E5",
          "#A8A690",
          "#D1BC63",
          "#94BA96",
          "#C4C176",
          "#D1A67B",
          "#AD9C61",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}",
        },
        legend: {
          orient: "horizontal",
          right: 10,
          top: 10,
          data:
            (summaryData.data_modality && summaryData.data_modality.name) || [],
        },
        grid: [
          {
            left: 20,
            bottom: 30,
            width: "46%",
            height: 340,
            containLabel: true,
          },
        ],
        xAxis: [
          {
            type: "value",
            max: seriesMax,
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            data:
              (summaryData.data_series && summaryData.data_series.name) ||
              yAxisEmpty,
            axisLabel: {
              interval: 0,
              rotate: 20,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "bar",
            stack: "chart",
            z: 3,
            seriesLayoutBy: "column",
            barMaxWidth: 60,
            label: {
              normal: {
                position: "right",
                show: !!summaryData.data_series.data,
              },
            },
            data: summaryData.data_series.data || [0],
          },
          {
            type: "pie",
            radius: [0, "60%"],
            hoverAnimation: false,
            legendHoverLink: false,
            label: {
              show: true,
              color: "#333",
              formatter: "{b}: {c}",
            },
            labelLine: {
              lineStyle: {
                color: "#333",
              },
            },
            emphasis: emphasis,
            center: ["75%", "50%"],
            data: summaryData.data_modality.data || noModality,
          },
        ],
      };

      let analysis_yAxis = "";
      if (summaryData.data_application.data) {
        let dataMax = Math.max(summaryData.data_application.data);
        dataMax =
          Math.floor(dataMax * 1.2) < 10 ? 10 : Math.floor(dataMax * 1.2);
        analysis_yAxis = [{ type: "value", minInterval: 1, max: dataMax }];
      } else {
        analysis_yAxis = [
          {
            type: "value",
            minInterval: 1,
            max: 500,
          },
        ];
      }
      this.analysis = {
        color: ["#7CB5EC"],
        tooltip: {
          trigger: "axis",
          show: !!summaryData.data_application.data,
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 20,
          right: 30,
          bottom: 20,
          height: 240,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data:
              (summaryData.data_application &&
                summaryData.data_application.name) ||
              [],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: analysis_yAxis,
        series: [
          {
            type: "bar",
            barWidth: 24,
            label: {
              show: !!summaryData.data_application.data,
              position: "top",
              color: "#333",
            },
            data:
              summaryData.data_application.data ||
              new Array(summaryData.data_application.name.length).fill(0),
          },
        ],
      };

      this.showDataTag =
        summaryData.data_tags && summaryData.data_tags.length > 0;
      this.treemap = {
        color: [
          "#ED925A",
          "#83B96C",
          "#7CB5EC",
          "#78C693",
          "#F8A780",
          "#C79C96",
          "#E8C358",
          "#ED928D",
          "#B8C458",
          "#7A8B9D",
          "#A7B481",
          "#EDAF5A",
          "#96BABE",
          "#9BB4D5",
          "#E3CB69",
          "#C080C4",
          "#78C693",
          "#CB9778",
          "#7B80E5",
          "#A8A690",
          "#D1BC63",
          "#94BA96",
          "#C4C176",
          "#D1A67B",
          "#AD9C61",
        ],
        tooltip: {
          formatter: function (info) {
            return info.name + "：" + info.value;
          },
        },
        series: [
          {
            type: "treemap",
            roam: false,
            label: {
              show: true,
              formatter: "{b}：{c}",
            },
            breadcrumb: {
              show: false,
            },
            nodeClick: false,
            data: summaryData.data_tags,
          },
        ],
      };
    },
  },
};
</script>

<style lang="less">
.summary {
  .page-title {
    margin-bottom: 10px;
    border-bottom: none;
  }
  .total-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &.low-level {
      justify-content: flex-start;
      .item {
        max-width: 400px;
      }
    }
    .item {
      flex: 1;
      margin-right: 20px;
      padding: 20px;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
      &:first-child {
        background: #7cb5ec;
      }
      &:nth-child(2) {
        background: #78c693;
      }
      &:nth-child(3) {
        background: #58bfbd;
      }
      &:nth-child(4) {
        background: #f5ce60;
      }
      &:last-child {
        margin-right: 0;
        background: #647e8e;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
      .item-title {
        margin-bottom: 15px;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
      }
      .item-number {
        height: 45px;
        line-height: 45px;
        font-size: 45px;
        font-weight: 600;
      }
    }
  }
  .chart-content {
    display: flex;
    flex-wrap: wrap;
    .chart-item {
      margin-bottom: 20px;
      border-radius: 5px;
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
      background: #fff;
      &:nth-child(2n + 1) {
        width: 40%;
        margin-right: 20px;
      }
      &:nth-child(2n + 2) {
        width: calc(60% - 20px);
      }
      &.age-sex-chart {
        .echarts {
          height: 400px;
        }
      }
      &.modality-chart {
        .echarts {
          height: 400px;
        }
      }
      &.analysis-chart {
        .echarts {
          height: 300px;
        }
      }
      &.tags-chart {
        .echarts {
          height: 300px;
        }
      }
      .chart-title {
        padding: 10px 20px;
        border-bottom: 1px solid #dee2e6;
        font-size: 20px;
      }
      .echarts {
        width: 100% !important;
      }
      .chart-body {
        padding: 30px 80px;
        .empty {
          height: 240px;
          line-height: 240px;
          text-align: center;
          background: #ebebeb;
        }
      }
    }
  }
}
</style>
